import React, { FC } from 'react'
import { CellProps } from 'react-table'

import Checkbox from '@src/components/Checkbox'
import useOrgStructureStore from '@pages/Team/Members/List/store'

import { OrgStructureRecord } from '@pages/Team/Members/types'

interface SelectCellProps {
  data: CellProps<OrgStructureRecord>
}

const SelectCell: FC<SelectCellProps> = ({ data }) => {
  const record = data.row?.original
  const selectRecords = useOrgStructureStore.use.selectRecords()

  const handleSelectRows = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e?.target?.checked
    const index = data.row.id
    selectRecords(+index, isChecked)
  }

  return (
    <Checkbox
      className="flex items-center justify-center p-0 m-0"
      onChange={handleSelectRows}
      checked={record.isSelected}
      onClick={(e) => e.stopPropagation()}
    />
  )
}

export default SelectCell
