import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'
import { subscribeWithSelector, devtools } from 'zustand/middleware'
import { produce } from 'immer'

import { actionOptions } from '../components/BulkAction/constants'
import { createSelectors } from '@store/utils'
import { OrgStructureUserListItem } from '@models/OrgStructure'

export type Filter = {
  department: Option
  jobRole: Option
  location: Option
  manager: Option
  search: string
  page: number
  limit: number
}

export enum BulkAction {
  downloadAttachments,
  setAsPaid,
}

export enum BulkSelect {
  page = 'page',
  all = 'all',
}

export interface OrgStructureState {
  filter: Partial<Filter>
  count: number
  records: (OrgStructureUserListItem & { isSelected?: boolean })[]
  bulkAction: Option
  importTaskId: string | number | null
  bulkSelect: BulkSelect | null
}

export interface OrgStructureActions {
  setFilter: (step: Partial<Filter>) => void
  setSearch: (search: string) => void
  resetFilter: () => void
  setRecords: (items: OrgStructureUserListItem[], count: number) => void
  selectRecords: (index: number | BulkSelect, checked: boolean) => void
  removeSelection: () => void
  setBulkAction: (action: Option) => void
  setImportTaskId: (taskId: string | number | null) => void
}

const createOrgStructureList = immer<OrgStructureState & OrgStructureActions>((set) => ({
  filter: {
    limit: 30,
    page: 1,
  },
  count: 0,
  records: [],
  bulkAction: actionOptions[0],
  importTaskId: null,
  bulkSelect: null,
  setFilter: (filter) =>
    set(
      produce((state: OrgStructureState) => {
        state.filter = { ...state.filter, ...filter, page: filter.page ?? 1 }
      }),
    ),
  setSearch: (search) =>
    set(
      produce((state: OrgStructureState) => {
        if (search !== state.filter.search) {
          state.filter = { ...state.filter, search, page: 1 }
        }
      }),
    ),
  resetFilter: () =>
    set(
      produce((state: OrgStructureState) => {
        state.filter = { limit: state.filter.limit, page: 1 }
      }),
    ),
  setRecords: (items, count) =>
    set(
      produce((state: OrgStructureState) => {
        state.records =
          state.bulkSelect === BulkSelect.all
            ? items.map((record) => ({
                ...record,
                isSelected: true,
              }))
            : items
        state.count = count
        if (state.bulkSelect === BulkSelect.page) {
          state.bulkSelect = null
        }
      }),
    ),
  selectRecords: (index, checked) =>
    set(
      produce((state: OrgStructureState) => {
        if (index in BulkSelect) {
          state.bulkSelect = index as BulkSelect
          state.records = state.records.map((record) => ({ ...record, isSelected: checked }))
        } else {
          state.bulkSelect = null
          state.records[index as number].isSelected = checked
        }
      }),
    ),
  removeSelection: () =>
    set(
      produce((state: OrgStructureState) => {
        state.bulkSelect = null
        state.records = state.records.map((record) => ({ ...record, isSelected: false }))
      }),
    ),

  setBulkAction: (action) =>
    set(
      produce((state: OrgStructureState) => {
        state.bulkAction = action
      }),
    ),
  setImportTaskId: (taskId) =>
    set(
      produce((state: OrgStructureState) => {
        state.importTaskId = taskId
      }),
    ),
}))

const name = 'orgStructureList'
const useOrgStructureListStoreBase = create(
  devtools(subscribeWithSelector(createOrgStructureList), { name, store: name }),
)

export const useOrgStructureStore = createSelectors(useOrgStructureListStoreBase)
export default useOrgStructureStore
