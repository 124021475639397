import React, { FC } from 'react'
import cx from 'clsx'

interface LabelProps {
  value?: string | number
  classNames?: string
}

const Label: FC<LabelProps> = ({ value, classNames }) => {
  return value ? (
    <div
      className={cx(
        'py-0.5 text-center h-6 flex w-fit px-3  items-center justify-center rounded-lg border',
        classNames,
      )}
    >
      {value}
    </div>
  ) : null
}

export default Label
