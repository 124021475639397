import useGetAccount from '@helpers/hooks/useGetAccount'
import { Channel } from '@type/common'

export const useGetConnectedChannel = (): Channel | null => {
  const user = useGetAccount()
  if (!user) {
    return null
  }
  const userChannels = user.apps || {}
  return Object.keys(userChannels).find((channel) => userChannels[channel as Channel]) as Channel
}
