import i18n from '@src/i18n'

import { Actions } from './types'

export const actions: { [key in Actions]: string } = {
  [Actions.InviteUsers]: i18n.t('org:bulkActions.inviteUsersLabel'),
  [Actions.AssignDepartment]: i18n.t('org:bulkActions.assignDepartmentLabel'),
  [Actions.AssignLocation]: i18n.t('org:bulkActions.assignLocationLabel'),
  [Actions.AssignManager]: i18n.t('org:bulkActions.assignManagerLabel'),
  [Actions.SetTimeOffLimit]: i18n.t('org:bulkActions.setTimeOffLimit'),
}

export const actionOptions = Object.keys(actions).map((key) => ({
  label: actions[key as Actions],
  value: key,
}))
