import { useQueryClient } from '@tanstack/react-query'
import { generatePath } from 'react-router-dom'

import { useDelete, useGetOne, useCreate, usePatch } from '../request'
import { AccountingKeys } from '../Keys'
import {
  BudgetBreakdownFilters,
  BudgetBreakdownResponse,
  CostCenterBodyRequest,
  CostCenterBodyResponse,
  CostCenterListResponse,
} from '@src/models/CostCenters'

export const PATHS = {
  costCenters: '/api/accounting/cost-center',
  specificCostCenter: '/api/accounting/cost-center/:id',
  specificCostCenterBudget: '/api/accounting/cost-center/:id/budgets/:date',
  constCenterBudgetBreakdown: '/api/accounting/cost-center/:id/budgets/:date/breakdown',
  constCenterBudgetBreakdownFilters: '/api/accounting/cost-center/:id/budgets/:date/breakdown/filters',
}

export const useGetCostCenters = (search?: string, enabled = true) =>
  useGetOne<CostCenterListResponse>([AccountingKeys.CostCenter], PATHS.costCenters, {
    query: {
      enabled,
    },
    axios: {
      params: { search },
    },
  })

export const useGetCostCenterDetail = (id: string | undefined, enabled = true, date = '') => {
  return useGetOne<CostCenterBodyResponse>(
    [AccountingKeys.CostCenter, id],
    generatePath(PATHS.specificCostCenter, { id: id || '', date }),
    {
      query: {
        enabled,
        suspense: true,
      },
    },
  )
}
export const useGetCostCenterBudgetDetail = (id = '', date = '') => {
  return useGetOne<CostCenterBodyResponse>(
    [AccountingKeys.CostCenter, id, date],
    generatePath(PATHS.specificCostCenterBudget, { id, date }),
    {
      query: {
        enabled: !!id && !!date,
      },
    },
  )
}

export const useGetCostCenterBudgetBreakdown = (id = '', date = '', filters = {}, enabled = true) => {
  return useGetOne<BudgetBreakdownResponse>(
    [AccountingKeys.CostCenter, id, date],
    generatePath(PATHS.constCenterBudgetBreakdown, { id, date }),
    {
      query: {
        enabled: !!id && !!date && enabled,
      },
      axios: {
        params: filters,
      },
    },
  )
}

export const useGetCostCenterBudgetBreakdownFilters = (id = '', date = '') => {
  return useGetOne<BudgetBreakdownFilters>(
    [AccountingKeys.CostCenterBreakdownFilters],
    generatePath(PATHS.constCenterBudgetBreakdownFilters, { id, date }),
    {
      query: {
        enabled: !!id && !!date,
      },
    },
  )
}

export const useCreateCostCenter = () => useCreate<CostCenterBodyRequest>(PATHS.costCenters)

export const useUpdateCostCenter = (id: string | undefined) => {
  const cache = useQueryClient()
  return usePatch<Partial<CostCenterBodyRequest>, CostCenterListResponse>(generatePath(`${PATHS.costCenters}/${id}`), {
    mutation: {
      onSuccess: () => {
        cache.invalidateQueries([AccountingKeys.CostCenter])
      },
    },
  })
}

export const useDeleteCostCenter = (id: string) => {
  const cache = useQueryClient()
  return useDelete(generatePath(PATHS.specificCostCenter, { id }), {
    mutation: {
      onSuccess: () => {
        cache.invalidateQueries([AccountingKeys.CostCenter])
      },
    },
  })
}
