import React, { useCallback, useEffect, useMemo } from 'react'
import { CellProps, Column } from 'react-table'
import { useTranslation } from 'react-i18next'
import { toast } from '@src/helpers/toaster'
import { AxiosError } from 'axios'
import { useQueryClient } from '@tanstack/react-query'

import Palm from '@components/Icons/Palm'
import { OrgStructureRecord } from '@pages/Team/Members/types'
import Actions from '@pages/Team/Members/List/components/Columns/Actions'
import Role from '@pages/Team/Members/List/components/Columns/Role'
import Label from '@pages/Team/Members/List/components/Columns/Label'
import SelectHeader from '@pages/Team/Members/List/components/Columns/SelectHeader'
import SelectCell from '@pages/Team/Members/List/components/Columns/SelectCell'

import { useIsAdmin } from '@src/helpers'
import { useGetTaskStatus } from '@queries/OrgStructure'
import { OrgStructureKeys } from '@queries/Keys'

import { TimeOffDuration } from '@type/common'
import Source from '@pages/Team/Members/List/components/Columns/Source'

interface UseGetTableColumnsProps {
  onDeleteRecord: (record: OrgStructureRecord) => void
}

export const useGetTableColumns = ({ onDeleteRecord }: UseGetTableColumnsProps) => {
  const isAdmin = useIsAdmin()
  const { t } = useTranslation('org')
  const { t: tCommon } = useTranslation('common')

  const getTimeOffString = useCallback((record: OrgStructureRecord, prop: 'limit' | 'duration') => {
    let amount = record.time_off[prop]
    if (!amount) {
      amount = 0
    }
    const type = (record.time_off.type || TimeOffDuration.day) as TimeOffDuration
    const postfix = tCommon(type, { count: amount })
    return `${amount} ${postfix} `
  }, [])

  return useMemo<Column<OrgStructureRecord>[]>(() => {
    const actionCol = isAdmin
      ? [
          {
            id: 'actions',
            accessor: (record: OrgStructureRecord) => <Actions record={record} onDeleteRecord={onDeleteRecord} />,
          },
        ]
      : []

    return [
      {
        Header: () => <SelectHeader />,
        id: 'select_row',
        Cell: (cell: CellProps<OrgStructureRecord>) => <SelectCell data={cell} />,
      },
      {
        Header: t('tableRows.fullNameTitle'),
        accessor: (record) =>
          record.is_vacation ? (
            <div className="flex items-center gap-1">
              <Palm className="h-4 w-4" />
              <span>{record.full_name}</span>
            </div>
          ) : (
            record.full_name
          ),
      },
      {
        Header: t('tableRows.emailTitle'),
        accessor: 'email',
      },
      {
        Header: t('tableRows.roleLabel'),
        id: 'role',
        accessor: (record: OrgStructureRecord) => <Role record={record} />,
      },
      {
        Header: t('tableRows.departmentTitle'),
        id: 'department',
        accessor: (record: OrgStructureRecord) => (
          <div>
            <Label value={record?.department?.name} classNames="bg-purple-50 border-purple-200 text-brand_primary" />
          </div>
        ),
      },
      {
        Header: t('tableRows.jobTitle'),
        id: 'job_title',
        accessor: (record: OrgStructureRecord) => (
          <Label value={record?.job_role?.name} classNames="bg-emerald-50 border-emerald-200 text-emerald-700" />
        ),
      },
      {
        Header: t('tableRows.locationTitle'),
        id: 'location',
        accessor: (record: OrgStructureRecord) => (
          <div>
            <Label value={record?.location?.name} classNames="bg-sky-50 border-sky-200 text-sky-700" />
          </div>
        ),
      },
      {
        Header: t('tableRows.managerTitle'),
        id: 'manager',
        accessor: (record: OrgStructureRecord) => record.manager?.full_name,
      },
      {
        Header: t('tableRows.timeOffTaken'),
        id: 'time_off_taken',
        accessor: (record: OrgStructureRecord) => getTimeOffString(record, 'duration'),
      },
      {
        Header: t('tableRows.timeOffLimit'),
        id: 'time_off_limit',
        accessor: (record: OrgStructureRecord) => getTimeOffString(record, 'limit'),
      },
      {
        Header: () => <div className="flex justify-center">{t('tableRows.source')}</div>,
        id: 'source',
        Cell: (cell: CellProps<OrgStructureRecord>) => <Source user={cell.row.original} />,
      },
      ...actionCol,
    ]
  }, [])
}

export const useCheckImportTaskStatus = (
  importTaskId: string | number | null,
  setImportTaskId: (id: string | number | null) => void,
) => {
  const { mutateAsync: fetchTaskStatus } = useGetTaskStatus()
  const cache = useQueryClient()

  const checkImportStatus = async () => {
    if (!importTaskId) {
      return
    }
    try {
      const isTaskCompleted = await fetchTaskStatus(importTaskId)

      if (isTaskCompleted) {
        setImportTaskId(null)
        cache.invalidateQueries([OrgStructureKeys.OrgStructure])
        cache.invalidateQueries([OrgStructureKeys.Filters])
      } else {
        setTimeout(() => checkImportStatus(), 1000)
      }
    } catch (e) {
      toast.error((e as AxiosError).message)
    }
  }

  useEffect(() => {
    checkImportStatus()
  }, [importTaskId])
}
