import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from '@src/helpers/toaster'
import { useTranslation } from 'react-i18next'
import { IconLock, IconPencil, IconTrash } from '@tabler/icons-react'

import Tooltip from '@src/components/Tooltip'

import { useApproveJoinTeam, useGetMyAccount } from '@queries/Account'

import routes from '@src/Routes/routes'
import { OrgStructureRecord } from '@pages/Team/Members/types'
import { requestErrorsHandler } from '@src/helpers/utils'

import styles from './index.module.css'

export interface ActionsProps {
  record: OrgStructureRecord
  onDeleteRecord: (record: OrgStructureRecord) => void
}

const Actions: React.FC<ActionsProps> = ({ record, onDeleteRecord }) => {
  const { data: account } = useGetMyAccount()
  const { mutateAsync: approveJoinTeam } = useApproveJoinTeam()
  const navigate = useNavigate()
  const { t } = useTranslation('common')

  const handleEditClick: React.MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      e?.stopPropagation()
      navigate(routes.private.organizationalStructureById.replace(':id', record.id.toString()))
    },
    [record],
  )

  const handleDeleteClick: React.MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      e?.stopPropagation()
      onDeleteRecord(record)
    },
    [record],
  )

  const handleApproveInviteClick: React.MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      e?.stopPropagation()
      if (!record?.join_request) return
      try {
        approveJoinTeam(record.join_request)
        toast.success(t('invites.acceptInviteSuccess'))
      } catch (err) {
        requestErrorsHandler(err)
      }
    },
    [record],
  )

  return (
    <>
      <div className="grid grid-cols-3 gap-1">
        <div className="h-4">
          {record.join_request !== null && (
            <button
              onClick={handleApproveInviteClick}
              className={styles.actionsButton}
              data-tooltip-content={t('userRoles.acceptInviteTip')}
              data-tooltip-id="actions_tip"
            >
              <IconLock className="h-4 w-4" />
            </button>
          )}
        </div>
        <button
          data-tooltip-id="actions_tip"
          data-tooltip-content={t('usersTips.edit')}
          onClick={handleEditClick}
          className={styles.actionsButton}
          data-qa="edit-button"
        >
          <IconPencil className="h-4 w-4" />
        </button>

        <div className="h-4">
          {account?.id !== record.auth_user_id && (
            <button
              data-tooltip-content={t('usersTips.delete')}
              data-tooltip-id="actions_tip"
              onClick={handleDeleteClick}
              className={styles.actionsButton}
              data-qa="delete-button"
            >
              <IconTrash className="h-4 w-4" />
            </button>
          )}
        </div>
      </div>
      <Tooltip place="left" id="actions_tip" />
    </>
  )
}

export default Actions
