import { useCallback } from 'react'
import { generatePath, useLocation, useNavigate, useParams } from 'react-router-dom'

import { useUpdateSettings } from '@queries/Account'
import useStore from '@src/store'
import useGetAccount from './useGetAccount'

import { OrganizationListItem } from '@models/ApproveitAccounting'
import routes from '@src/Routes/routes'

export const useSwitchActiveOrganization = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const params = useParams<{ id: string }>()
  const switchOrganization = useStore.use.switchOrganization()
  const { mutateAsync: updateSettings } = useUpdateSettings()
  const user = useGetAccount()

  const setOrganization = (organization: Pick<OrganizationListItem, 'id' | 'name' | 'integration'>) => {
    switchOrganization(
      organization.id,
      organization.name,
      organization.integration
        ? {
            tenantId: organization.integration.tenant_id,
            integrationId: organization.integration.integration_id,
          }
        : undefined,
    )
    if (user?.settings.active_organization !== organization.id) {
      updateSettings({
        active_organization: organization.id,
      })
    }
    if (params.id && location.pathname === generatePath(routes.private.organization, { id: params.id })) {
      navigate(generatePath(routes.private.organization, { id: organization.id }))
    }
  }

  return useCallback(setOrganization, [user?.settings.active_organization, location])
}
