import React from 'react'

export const Quantity = (props: React.ComponentProps<'svg'>): JSX.Element => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M3.11068 4L1.77734 4.22223C2.13957 2.55245 3.19157 2.00156 4.22179 2C4.88779 1.99889 5.54445 2.228 5.99957 2.44445C6.45468 2.228 7.11068 2 7.77734 2C8.80201 2 9.85957 2.55245 10.2218 4.22223L8.88845 4C9.01268 4.46667 9.28579 5.28712 8.88845 6C8.22623 5.406 6.69512 4.50911 5.99957 4C5.30401 4.50911 3.7729 5.406 3.11068 6C2.71334 5.28734 2.98645 4.46667 3.11068 4Z"
        fill="white"
        stroke="#662DEF"
        strokeWidth="0.666667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.99958 4C5.8318 4.86422 5.59714 7.00733 5.99958 8.66667"
        stroke="#662DEF"
        strokeWidth="0.666667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.33203 10.0003H8.66536C7.60159 8.98144 7.10981 8.6681 5.9987 8.66699C4.88759 8.66588 3.75248 9.41855 3.33203 10.0003Z"
        fill="white"
        stroke="#662DEF"
        strokeWidth="0.666667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default Quantity
