import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react'
import cx from 'clsx'

import { Collapse } from '../Collapse'

import { ChevronRightIcon } from '@heroicons/react/outline'

import styles from './index.module.css'
import { twMerge } from 'tailwind-merge'

export interface CollapsibleProps {
  className?: string
  collapsedClassName?: string
  titleClassName?: string
  subtitleClassName?: string
  openClassName?: string
  iconClassName?: string
  title?: string
  subtitle?: string
  collapsedComponent?: React.ReactNode
  expanded?: boolean
  defaultExpanded?: boolean
  controlOutside?: boolean
  onToggle?: () => void
}

export type CollapsibleRef = { setIsExpanded: (isExpanded: boolean) => void }
export const Collapsible = forwardRef<CollapsibleRef, React.PropsWithChildren<CollapsibleProps>>(
  (
    {
      children,
      className,
      collapsedClassName,
      titleClassName,
      subtitleClassName,
      openClassName,
      iconClassName,
      title,
      subtitle,
      collapsedComponent,
      expanded = false,
      defaultExpanded = false,
      controlOutside = false,
      onToggle,
    },
    ref,
  ) => {
    const [isExpanded, setIsExpanded] = useState<boolean>(defaultExpanded)

    const handlePanelClick = useCallback(() => {
      if (!controlOutside) {
        setIsExpanded((st) => !st)
      } else {
        onToggle?.()
      }
    }, [onToggle, controlOutside])

    useEffect(() => {
      if (!controlOutside) {
        setIsExpanded(defaultExpanded)
      }
    }, [])

    useImperativeHandle(ref, () => ({ setIsExpanded }), [setIsExpanded])

    const isElExpanded = controlOutside ? expanded : isExpanded

    return (
      <div
        className={twMerge(
          'px-6 flex flex-col transition-colors text-black',
          className,
          (isExpanded || expanded) && openClassName,
        )}
      >
        <div
          className={cx('flex items-center justify-between py-5 cursor-pointer min-h-[78px]', collapsedClassName)}
          onClick={handlePanelClick}
        >
          {collapsedComponent || (
            <div>
              <p className={cx(styles.title, titleClassName)}>{title}</p>
              <p className={cx(styles.subtitle, subtitleClassName)}>{subtitle}</p>
            </div>
          )}
          <ChevronRightIcon
            className={twMerge(
              'w-5 h-5 text-purple-700 transition-transform',
              iconClassName,
              isElExpanded && 'transform rotate-90',
            )}
          />
        </div>
        <Collapse expanded={isElExpanded}>{children}</Collapse>
      </div>
    )
  },
)

export default Collapsible
