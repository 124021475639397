import { useCallback, useState } from 'react'

import { useImportUsersFromMicrosoft, useImportUsersFromSlack } from '@queries/OrgStructure'
import { useGetConnectedChannel } from '@helpers/hooks/useGetConnectedChannel'
import { useCheckImportTaskStatus } from '../List/utils'
import { communicationTypes } from '@src/constants'

import { Channel, CommunicationType } from '@type/common'

export const useImportUsersFromChannel = () => {
  const [importTaskId, setImportTaskId] = useState<number | string | null>(null)
  const { mutateAsync: importFromSlack } = useImportUsersFromSlack()
  const { mutateAsync: importFromMicrosoft } = useImportUsersFromMicrosoft()

  useCheckImportTaskStatus(importTaskId, setImportTaskId)

  const connectedChannel = useGetConnectedChannel()
  const channels = {
    [Channel.slack]: {
      ...communicationTypes[CommunicationType.slack],
      importHandler: importFromSlack,
    },
    [Channel.microsoft]: {
      ...communicationTypes[CommunicationType.microsoft],
      importHandler: importFromMicrosoft,
    },
  }

  const handleImport = useCallback(async () => {
    let importTaskId

    if (connectedChannel) {
      importTaskId = await channels[connectedChannel]?.importHandler({})
    }

    if (importTaskId) {
      setImportTaskId(importTaskId as number)
    }
  }, [])

  return {
    handleImport,
    isLoading: !!importTaskId,
  }
}

export default useImportUsersFromChannel
