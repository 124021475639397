import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { ContactParty } from '@models/ApproveitAccounting'

export const useGetContactParty = () => {
  const { t } = useTranslation('accounting', { keyPrefix: 'contact' })
  const { contactParty } = useParams<{ contactParty: ContactParty }>()

  return { party: contactParty, label: contactParty === ContactParty.customer ? t('customerLabel') : t('vendorLabel') }
}
