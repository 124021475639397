import React, { Ref, useMemo, useRef, useState } from 'react'
import { DropdownIndicatorProps, GroupBase, SelectInstance, components as comp } from 'react-select'
import AsyncSelect, { AsyncProps } from 'react-select/async'
import cx from 'clsx'

import Tooltip from '@src/components/Tooltip'
import { QuestionMarkCircleIcon } from '@heroicons/react/outline'
import { IconChevronDown } from '@tabler/icons-react'
import { getSelectStyles } from './utils'

export { useSelectHelpers } from './hooks/useSelectHelpers'

interface ReactSelectProps<Option> {
  label?: string | React.ReactElement
  errorMessage?: string
  tooltip?: string
  required?: boolean
  refObj?: React.RefObject<SelectInstance<Option>>
}

export type Option = {
  label: string
  value: string
  [key: string]: unknown
}

export const ReactSelect = <
  Option = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  className,
  label,
  tooltip,
  errorMessage,
  required,
  components,
  ...props
}: AsyncProps<Option, IsMulti, Group> & ReactSelectProps<Option>) => {
  const [alignRight, setAlignRight] = useState(false)
  const styles = useMemo(
    () => getSelectStyles<Option, IsMulti, Group>(errorMessage, alignRight),
    [errorMessage, alignRight],
  )
  const selectRef = useRef<SelectInstance<Option, IsMulti, Group>>()

  const menuObserver = useRef<IntersectionObserver>()
  const ref = props.refObj || selectRef

  const onMenuOpen = () => {
    const observeOnscreen: IntersectionObserverCallback = (entries = []) => {
      const { boundingClientRect, intersectionRect } = entries[0]
      const isOffscreen = boundingClientRect.width <= intersectionRect.width
      setAlignRight(!isOffscreen)
    }

    setTimeout(() => {
      const menuList = ref.current?.menuListRef
      if (menuList) {
        menuObserver.current = new IntersectionObserver(observeOnscreen)
        menuObserver.current.observe(menuList)
      }
    })
    props.onMenuOpen?.()
  }

  const onMenuClose = () => {
    setAlignRight(false)
    menuObserver.current && menuObserver.current.disconnect()
    props.onMenuClose?.()
  }

  const tooltipId = `${props.name || props.id || 'select'}-${label}`
  const labelElement = label && (
    <label className={cx('flex items-center text-base text-gray-700 mb-0.5 break-normal whitespace-normal')}>
      {tooltip ? (
        <>
          <div data-tooltip-id={tooltipId} className="flex items-center">
            {label}
            <QuestionMarkCircleIcon className="h-3 ml-1" />
          </div>
          <Tooltip id={tooltipId}>
            <span>{tooltip}</span>
          </Tooltip>
        </>
      ) : (
        label
      )}
      {required && <span className="text-red-500">*</span>}
    </label>
  )

  return (
    <div className={cx(className, !errorMessage && 'mb-[1rem]')} onClick={(e) => e.stopPropagation()}>
      {labelElement}
      <AsyncSelect<Option, IsMulti, Group>
        {...props}
        ref={(props.refObj || selectRef) as unknown as Ref<SelectInstance<Option, IsMulti, Group>>}
        className={cx('rounded-base', className)}
        components={{
          ...components,
          DropdownIndicator: (props: DropdownIndicatorProps<Option, IsMulti, Group>) => {
            return (
              comp.DropdownIndicator && (
                <comp.DropdownIndicator {...props}>
                  <IconChevronDown className="text-neutral-300" stroke={1.5} size={16} />
                </comp.DropdownIndicator>
              )
            )
          },
        }}
        styles={{ ...styles, ...props.styles }}
        classNames={{
          ...props.classNames,
          container: (props) => cx(props.className, props.isDisabled ? '!bg-netural-50 !border-gray-300' : ''),
        }}
        onMenuOpen={onMenuOpen}
        onMenuClose={onMenuClose}
      />
      {errorMessage && <div className="w-full mt-0.5 text-[11px] text-red-500">{errorMessage}</div>}
    </div>
  )
}

export default ReactSelect
