import React, { useCallback, useEffect, useRef } from 'react'
import cx from 'clsx'
import { ControlledMenu, MenuItem, useMenuState } from '@szhsin/react-menu'
import { useTranslation } from 'react-i18next'
import Checkbox from '@src/components/Checkbox'

import useOrgStructureStore, { BulkSelect } from '@pages/Team/Members/List/store'
import { IconCheck, IconChevronDown } from '@tabler/icons-react'

import styles from './index.module.css'

const SelectHeader = () => {
  const { t } = useTranslation('org')
  const selectRecords = useOrgStructureStore.use.selectRecords()
  const removeSelection = useOrgStructureStore.use.removeSelection()
  const filter = useOrgStructureStore.use.filter()
  const count = useOrgStructureStore.use.count()
  const records = useOrgStructureStore.use.records()
  const selected = useOrgStructureStore.use.bulkSelect()
  const checkboxRef = useRef<HTMLInputElement>(null)
  const [{ state: menuState }, toggleMenu] = useMenuState()

  const limit = filter.limit || 30

  const pageSize = count > limit ? limit : count
  const allAreOnPage = pageSize < limit

  const selectOptions: Option<BulkSelect>[] = [
    { value: BulkSelect.page, label: t('selectOnPage', { value: pageSize }) },
    { value: BulkSelect.all, label: t('selectAll', { value: count }) },
  ]

  const handleSelect = (selectedOption: Option<BulkSelect>) => {
    toggleMenu()
    if (selectedOption.value !== selected) {
      selectRecords(selectedOption.value, true)
    }
  }

  const handleCheckboxClick = useCallback(() => {
    if (selected) {
      return removeSelection()
    }
    if (allAreOnPage) {
      selectRecords(BulkSelect.page, true)
    } else {
      toggleMenu()
    }
  }, [toggleMenu, removeSelection, selected, allAreOnPage])

  useEffect(() => {
    if (menuState === 'open') {
      toggleMenu()
    }
  }, [records])

  return (
    <div>
      <button
        className={cx(styles.selectHeader, {
          [styles.selectHeaderFocused]: menuState === 'open',
        })}
      >
        <div className="flex items-center justify-center -m-1 py-1 px-1.5 gap-0.5 w-10 cursor-pointer">
          <Checkbox
            ref={checkboxRef}
            checked={!!selected}
            onChange={handleCheckboxClick}
            disabled={!count}
            className="min-w-4"
          />

          <button
            onClick={() => toggleMenu()}
            disabled={!count}
            className={cx('cursor-pointer text-brand_primary', { 'text-gray-400': !count, invisible: allAreOnPage })}
          >
            <IconChevronDown width="0.75rem" height="0.75rem" />
          </button>
        </div>
      </button>

      <ControlledMenu
        menuClassName="!-top-1 !left-6 rounded-base text-xs "
        state={menuState}
        anchorRef={checkboxRef}
        className={styles.selectHeaderContainer}
      >
        {selectOptions.map((option) => {
          return (
            <MenuItem
              className="px-4 py-0.5 m-0 bg-transparent hover:bg-blue-50 normal-case font-normal relative"
              onClick={() => handleSelect(option)}
              key={option.value}
            >
              {selected === option.value && <IconCheck className="w-3 h-3 absolute left-[3px] text-brand_primary" />}
              {option.label}
            </MenuItem>
          )
        })}
      </ControlledMenu>
    </div>
  )
}

export default SelectHeader
