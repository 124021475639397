import React from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import { SingleValue } from 'react-select'

import { Icon } from '@svgr-iconkit/core'
import Megaphone2 from '@svgr-iconkit/xnix/icons/regular/megaphone-2'

import i18n from '@src/i18n'

import Button from '@components/Button'
import Popup from '@components/Popup'
import Input from '@components/Form/Input'
import ReactSelect from '@components/Form/ReactSelect'
import SwitchBox from '@components/SwitchBox'

import { useAddTeamUser, useInviteUser } from '@queries/Teams'

import { requestErrorsHandler } from '@helpers/utils'
import { RoleOptions } from '@helpers/constants'

import { Role } from '@type/common'
import { AddUserResponse } from '@models/User'

const Schema = Yup.object().shape({
  email: Yup.string()
    .email(i18n.t('builder:step.approversList.validation.maxLength', { max: 100 }))
    .required(
      i18n.t('builder:step.approversList.validation.fieldRequired', {
        field: i18n.t('builder:step.approversList.emailLabel'),
      }),
    ),
  name: Yup.string().required(
    i18n.t('builder:step.approversList.validation.fieldRequired', {
      field: i18n.t('builder:step.approversList.nameLabel'),
    }),
  ),
})

export interface QuickAddPopupProps {
  onClose: VoidFunction
  onAddUser?: (user: AddUserResponse) => Promise<void> | void
  isLoading?: boolean
}

export const QuickAddPopup: React.FC<QuickAddPopupProps> = ({
  onClose,
  onAddUser,
  isLoading: isLoadingOutside = false,
}) => {
  const { t } = useTranslation(['builder', 'common'])

  const { mutateAsync: createUser, isLoading: isCreateUserLoading } = useAddTeamUser({ reloadOrgStructure: false })
  const { mutateAsync: inviteUser, isLoading: isInviteUserLoading } = useInviteUser({ reloadOrgStructure: false })

  const isLoading = isCreateUserLoading || isInviteUserLoading || isLoadingOutside
  const formik = useFormik({
    validationSchema: Schema,
    initialValues: {
      name: '',
      email: '',
      invite: true,
      accessLevel: Role.requestor,
    },
    onSubmit: async (values) => {
      try {
        const user = await createUser({
          email: values.email,
          full_name: values.name,
          invite_role: values.invite ? values.accessLevel : Role.requestor,
        })

        if (values.invite) {
          await inviteUser({
            emails: [user.email],
            role: values.accessLevel || Role.requestor,
          })
        }

        if (onAddUser) {
          await onAddUser(user)
        }

        onClose()
      } catch (err) {
        requestErrorsHandler(err)
      }
    },
  })

  const accessLevelValue = RoleOptions.find((option) => option.value === formik.values.accessLevel)

  const handleChangeAccessLevel = (newValue: SingleValue<Option>) => {
    formik.setFieldValue('accessLevel', newValue?.value)
  }

  return (
    <Popup onClose={onClose} className="max-w-lg pt-3" allowCloseOnMissClick={false}>
      <form onSubmit={formik.handleSubmit} className="flex flex-col gap-3">
        <h4 className="border-b pb-2 font-medium">{t('builder:step.approversList.addUserLabel')}</h4>
        <div>
          <Input
            label={t('builder:step.approversList.nameLabel')}
            name="name"
            onChange={formik.handleChange}
            value={formik.values.name}
            errorMessage={formik.errors.name}
            required
          />
          <Input
            label={t('builder:step.approversList.emailLabel')}
            type="email"
            name="email"
            onChange={formik.handleChange}
            value={formik.values.email}
            errorMessage={formik.errors.email}
            required
          />
        </div>
        <SwitchBox
          label={t('builder:step.approversList.inviteLabel')}
          checked={formik.values.invite}
          onChange={formik.handleChange}
          className="justify-between border-b pb-2"
          name="invite"
          id="invite"
        />
        <div className="p-3 flex justify-between gap-3 rounded-base border text-sm bg-teal-50 text-grey_1">
          {t('builder:step.approversList.inviteMessage')}
          <Icon content={Megaphone2} className="h-8 w-8 text-black" />
        </div>
        {formik.values.invite && (
          <ReactSelect
            label={t('builder:step.approversList.chooseAccessLevelLabel')}
            options={RoleOptions}
            value={accessLevelValue}
            onChange={handleChangeAccessLevel}
          />
        )}
        <div className="flex gap-2 justify-end mt-4">
          <Button buttonType="button" onClick={onClose} type="outlined" loading={isLoading}>
            {t('common:cancel')}
          </Button>
          <Button buttonType="submit" loading={isLoading}>
            {t('common:save')}
          </Button>
        </div>
      </form>
    </Popup>
  )
}

export default React.memo(QuickAddPopup)
