import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { OnChangeValue, components } from 'react-select'
import { useTranslation } from 'react-i18next'
import Tooltip from '@src/components/Tooltip'

import ReactSelect from '@components/Form/ReactSelect'
import Button from '@components/Button'
import { IconChevronDown, IconHelp, IconPointFilled } from '@tabler/icons-react'

import { useIsAdmin } from '@src/helpers'
import { userRoleLabel } from '@src/constants'

import { useChangeUserRole, useInviteUser } from '@queries/Teams'
import useGetAccount from '@helpers/hooks/useGetAccount'
import { requestErrorsHandler } from '@helpers/utils'

import { OrgStructureRecord } from '@pages/Team/Members/types'
import { Role as UserRole } from '@type/common'

export interface RoleProps {
  record: OrgStructureRecord
}

const enum NotMemberValues {
  notInvited = 'notInvited',
  invited = 'invited',
  requestingInvite = 'requestingInvite',
}

const optionObject: Record<string, Option> = {
  [UserRole.admin]: { label: userRoleLabel.Admin, value: UserRole.admin },
  [UserRole.requestor]: { label: userRoleLabel.Requestor, value: UserRole.requestor },
  [NotMemberValues.notInvited]: { label: userRoleLabel.notInvited, value: 'notInvited' },
  [NotMemberValues.invited]: { label: userRoleLabel.invited, value: 'invited' },
  [NotMemberValues.requestingInvite]: { label: userRoleLabel.requestingInvite, value: 'requestingInvite' },
}

const userRoleOptions = [optionObject[UserRole.admin], optionObject[UserRole.requestor]]

const getRoleLabel = (user: OrgStructureRecord) => {
  if (user.auth_user_role) {
    return userRoleLabel[user.auth_user_role]
  }
  if (user.is_invited) {
    return userRoleLabel.invited
  }

  return userRoleLabel.notInvited
}

export const Role: React.FC<RoleProps> = ({ record }) => {
  const { t } = useTranslation(['common', 'org'])
  const isAdmin = useIsAdmin()
  const user = useGetAccount()

  const { mutateAsync: invite } = useInviteUser()
  const { mutateAsync: changeRole } = useChangeUserRole(record.auth_user_id)

  const defaultValue = useMemo(() => {
    if (record.auth_user_role) {
      return optionObject[record.auth_user_role]
    }

    if (record.join_request) {
      return optionObject[NotMemberValues.requestingInvite]
    }

    if (record.is_invited) {
      return optionObject[NotMemberValues.invited]
    }

    return optionObject[NotMemberValues.notInvited]
  }, [record.is_invited, record.auth_user_role, record.join_request])

  const [value, setValue] = useState<Option>(defaultValue)
  const [inviteRole, setInviteRole] = useState(UserRole.requestor)

  useEffect(() => {
    setValue(defaultValue)
  }, [defaultValue])

  const options = useMemo(() => {
    if (record.auth_user_role) {
      return userRoleOptions
    }
    if (!record.is_invited) {
      return [optionObject.notInvited]
    }
    return [optionObject.invited]
  }, [record.is_invited, record.auth_user_role])

  const roleLabel = getRoleLabel(record)

  const handleChange = useCallback(async (option: OnChangeValue<Option, false>) => {
    try {
      if (option) {
        setValue(option as Option)
        changeRole({ role_name: option?.value })
      }
    } catch (err) {
      requestErrorsHandler(err)
    }
  }, [])

  const handleInviteRoleChange = useCallback(async (e: React.ChangeEvent<HTMLInputElement>) => {
    e?.preventDefault()
    setInviteRole(e.target.value as UserRole)
  }, [])

  const handleInviteClick = useCallback(async () => {
    try {
      await invite({ emails: [record.email], role: inviteRole })
      // setValue(optionObject[NotMemberValues.invited])
    } catch (err) {
      requestErrorsHandler(err)
    }
  }, [inviteRole])

  const handleLabelClick: React.MouseEventHandler<HTMLLabelElement> = (e) => {
    e.preventDefault()
    setInviteRole(e.currentTarget.htmlFor as UserRole)
  }

  return isAdmin && user?.id !== record.auth_user_id ? (
    record.join_request ? (
      <div className="text-amber-700 flex items-center gap-1">
        {userRoleLabel.requestingInvite}
        <IconHelp
          className="inline w-3 h-3"
          data-tooltip-content={t('common:userRoles.inviteTip', { role: record.invite_role?.toLowerCase() })}
          data-tooltip-id="invited_tip"
        />
        <Tooltip id="invited_tip" />
      </div>
    ) : (
      <div
        onClick={(e) => {
          e.stopPropagation()
        }}
        className="flex justify-start"
      >
        <ReactSelect
          className="!mb-0 flex justify-center"
          options={options}
          value={value}
          onChange={handleChange}
          menuPortalTarget={document.body}
          components={{
            Menu: ({ children, ...propsInput }) => (
              <components.Menu {...propsInput}>
                <div className="px-2 pt-2 font-bold text-grey_1 z-20">{t('org:rolePicker.label')}</div>
                {children}
              </components.Menu>
            ),
            Option: ({ children, ...props }) => {
              if (!record.auth_user_role) {
                const description = record.is_invited
                  ? t('common:userRoles.description.invited')
                  : t('common:userRoles.description.notInvited')
                return (
                  <div className="px-2 flex flex-col" onClick={(e) => e.stopPropagation()}>
                    <span className="font-bold">{roleLabel}</span>
                    <span className="text-grey_1">{description}</span>

                    {props.data?.value === NotMemberValues.notInvited && (
                      <>
                        <div className="flex flex-col my-2">
                          <div className="flex gap-1">
                            <label
                              htmlFor={UserRole.admin}
                              className="mb-0 flex items-center gap-1"
                              onClick={handleLabelClick}
                            >
                              <input
                                type="radio"
                                value={UserRole.admin}
                                name="inviteRole"
                                onChange={handleInviteRoleChange}
                                checked={inviteRole === UserRole.admin}
                                id={UserRole.admin}
                              />{' '}
                              Invite as {userRoleLabel.Admin}
                            </label>
                          </div>
                          <div className="flex gap-1">
                            <label
                              htmlFor={UserRole.requestor}
                              className="mb-0 flex items-center gap-1"
                              onClick={handleLabelClick}
                            >
                              <input
                                type="radio"
                                value={UserRole.requestor}
                                name="inviteRole"
                                checked={inviteRole === UserRole.requestor}
                                onChange={handleInviteRoleChange}
                                id={UserRole.requestor}
                              />{' '}
                              Invite as {userRoleLabel.Requestor}
                            </label>
                          </div>
                        </div>
                        <div className="flex justify-end">
                          <Button onClick={handleInviteClick}>Invite</Button>
                        </div>
                      </>
                    )}
                  </div>
                )
              }
              return <components.Option {...props}>{children}</components.Option>
            },
            IndicatorsContainer: ({ children, ...props }) => {
              return (
                <components.IndicatorsContainer {...props}>
                  <IconChevronDown stroke={1.25} className="w-5 h-5 mr-0.5 -ml-1 text-slate-950 cursor-pointer" />
                  {children}
                </components.IndicatorsContainer>
              )
            },
          }}
          placeholder={roleLabel}
          styles={{
            placeholder: (base) => ({
              ...base,
              color: '#343A40',
            }),
            valueContainer: (base) => ({
              ...base,
              padding: 0,
            }),
            menu: (base) => ({
              ...base,
              width: '300px',
              borderRadius: '10px',
              overflow: 'hidden',
              paddingBottom: 8,
            }),
            menuPortal: (base) => ({
              ...base,
              zIndex: 20,
            }),
            control: (base) => ({
              ...base,
              border: 'none',
              background: 'transparent',
              outline: 'none',
              boxShadow: 'none',
              flexDirection: 'row-reverse',
            }),
            indicatorSeparator: () => ({ display: 'none' }),
            dropdownIndicator: () => ({ display: 'none' }),
            input: (base) => ({
              ...base,
              color: 'transparent',
            }),
          }}
        />
      </div>
    )
  ) : (
    <div className="flex items-center gap-2 h-[38px]">
      {!isAdmin ? <IconPointFilled className="w-3.5 h-3.5 -ml-1" /> : <div className="ml-[13px]" />}
      {roleLabel}
    </div>
  )
}

export default React.memo(Role)
