import React, { useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import Popup from '@components/Popup'
import SuspenseImage from '@components/SuspenseImage'
import Button from '@components/Button'
import useGetAccount from '@src/helpers/hooks/useGetAccount'
import routes from '@src/Routes/routes'
import { useIsAdmin } from '@src/helpers'

export interface PaywallProps {
  onClose: VoidFunction
  requestsCount?: number
}

export const Paywall: React.FC<PaywallProps> = ({ onClose, requestsCount }) => {
  const { t } = useTranslation('common', { keyPrefix: 'paywall.requestsCount' })
  const navigate = useNavigate()
  const isAdmin = useIsAdmin()
  const account = useGetAccount()

  const handleContactClick = useCallback(() => {
    Intercom('showNewMessage', t('chatMessage'))
  }, [])

  return (
    <Popup className="max-w-xl" onClose={onClose}>
      <div className="flex flex-col gap-3 items-center p-4">
        <SuspenseImage src="/paywalls/workflow-limit.svg" className="ml-4" />
        <h4 className="font-medium">{t('title')}</h4>
        <div className="mb-2 text-center">
          {isAdmin ? (
            <Trans
              t={t}
              i18nKey="textAdmin"
              values={{
                requestsCount: requestsCount,
              }}
              components={{
                req: <span className="font-bold" />,
              }}
            />
          ) : (
            <Trans
              t={t}
              i18nKey="textMember"
              values={{
                requestsCount: requestsCount,
                adminEmail: account?.team?.team_admins?.[0]?.email,
              }}
              components={{
                req: <span className="font-bold" />,
                email: (
                  <a
                    className="text-blue-500 underline hover:no-underline cursor-pointer"
                    href={`mailto:${account?.team?.team_admins?.[0].email}`}
                    target="_blank"
                    rel="noreferrer"
                  />
                ),
              }}
            />
          )}
        </div>
        <div className="flex gap-2 justify-center">
          {isAdmin ? (
            <>
              <Button type="outlined" onClick={onClose}>
                {t('backLabel')}
              </Button>
              <Button type="outlined" onClick={handleContactClick}>
                {t('contactSalesLabel')}
              </Button>
              <Button color="green" onClick={() => navigate(routes.private.billing)}>
                {t('upgradeLabel')}
              </Button>
            </>
          ) : (
            <Button color="green" onClick={onClose}>
              {t('okButton')}
            </Button>
          )}
        </div>
      </div>
    </Popup>
  )
}

export default Paywall
