import { DashboardKeys } from '../Keys'

import { useGetOne } from '../request'

import { DashboardResponse } from '@models/Dashboard'

const PATHS = {
  stats: '/api/teams/stats',
}

export const useGetStats = () => {
  return useGetOne<DashboardResponse>([DashboardKeys.Dashboard], `${PATHS.stats}`)
}
