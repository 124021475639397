import React, { FC } from 'react'

import { importVendors } from '@src/constants'

import { OrgStructureListItem } from '@models/OrgStructure'
import { ImportVendor } from '@type/common'

interface SourceProps {
  user: OrgStructureListItem
}

const mapping = [ImportVendor.slack, ImportVendor.microsoft, ImportVendor.bamboohr]

const getUserSource = (user: OrgStructureListItem): ImportVendor | undefined => {
  const roles = user.roles || []
  return mapping.find((role) => roles.includes(role + '_user'))
}

const Source: FC<SourceProps> = ({ user }) => {
  const source = getUserSource(user)
  const Icon = source ? importVendors[source]?.Icon : null

  if (!Icon) return null

  return (
    <div className="flex justify-center">
      <Icon className="w-5 h-5" />
    </div>
  )
}

export default Source
