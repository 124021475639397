import React, { useRef } from 'react'
import cx from 'clsx'
import { twMerge } from 'tailwind-merge'

import { IconCheck, IconX } from '@tabler/icons-react'

export interface SwitchBoxProps {
  checked?: boolean
  label?: string | React.ReactElement
  id?: string
  name?: string
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  className?: string
  labelClassName?: string
  disabled?: boolean
}

export const SwitchBox: React.FC<SwitchBoxProps> = ({
  checked = false,
  label,
  id,
  name,
  onChange,
  className,
  labelClassName,
  disabled = false,
}) => {
  const inputRef = useRef<HTMLInputElement>(null)
  return (
    <div className={twMerge(cx('flex items-center gap-2', disabled && 'opacity-50 pointer-events-none', className))}>
      {label && (
        <label htmlFor={id} className={twMerge(cx('mb-0 whitespace-normal text-black', labelClassName))}>
          {label}
        </label>
      )}
      <div
        className={cx(
          'relative rounded-full min-w-[48px] max-w-[48px] h-[22px] transition duration-200 ease-[cubic-bezier(0.4,0,0.2,1)] cursor-pointer',
          disabled && '!bg-slate-200 opacity-50',
          disabled && checked && '!bg-emerald-500',
          checked ? 'bg-emerald-500' : 'bg-neutral-200',
        )}
        onClick={(e) => {
          e.stopPropagation()
          inputRef?.current?.click?.()
        }}
      >
        {checked ? (
          <IconCheck className="absolute left-1 top-[3px] text-white" size={16} />
        ) : (
          <IconX className="absolute right-1 top-[2.75px] text-neutral-400" size={16.5} />
        )}
        <label
          htmlFor={id}
          className={cx(
            'absolute top-[3px] w-4 h-4 rounded-full transition transform duration-150 ease-[cubic-bezier(0.4,0,0.2,1)] cursor-pointer',
            checked ? ' right-1 bg-white' : 'left-1 bg-neutral-500',
          )}
          onClick={(e) => {
            e.stopPropagation()
          }}
        />
        <input
          type="checkbox"
          id={id}
          name={name}
          className="cursor-pointer appearance-none w-full h-full active:outline-none focus:outline-none"
          onChange={onChange}
          onClick={(e) => {
            e.stopPropagation()
          }}
          checked={checked}
          disabled={disabled}
          ref={inputRef}
        />
      </div>
    </div>
  )
}

export default SwitchBox
