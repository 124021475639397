import { useCallback, useMemo, useState } from 'react'

export const usePopupHandlers = (open = false) => {
  const [isPopupOpen, setIsPopupOpen] = useState(open)
  const handleTogglePopup = useCallback(() => setIsPopupOpen((st) => !st), [])

  return useMemo(
    () => ({
      isPopupOpen,
      setIsPopupOpen,
      handleTogglePopup,
    }),
    [isPopupOpen],
  )
}

export default usePopupHandlers
